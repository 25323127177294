<template>
  <div>
    <div ref="gantt" />
    <svg height="0" width="0" style="position: absolute">
      <defs>
        <linearGradient
          id="gradBuffer"
          x1="0%"
          y1="0%"
          x2="100%"
          y2="100%"
        >
          <stop offset="0%" style="stop-color:rgb(255,0,0);stop-opacity:0.7" />
          <stop offset="45%" style="stop-color:rgb(255,255,0);stop-opacity:0.7" />
          <stop offset="55%" style="stop-color:rgb(255,255,0);stop-opacity:0.7" />
          <stop offset="100%" style="stop-color:rgb(34, 255, 0);stop-opacity:0.7" />
        </linearGradient>
      </defs>
    </svg>
  </div>
</template>

<script>
import Gantt from 'ccpm-frappe-gantt'
import _omit from 'lodash/omit'
import _debounce from 'lodash/debounce'
import areIntervalsOverlapping from 'date-fns/areIntervalsOverlapping'
import parseISO from 'date-fns/parseISO'

export default {
  name: 'FrappeGantt',
  props: {
    value: {
      type: Array,
      default: () => ([]),
    },
    showConflict: {
      type: Boolean,
      default: false,
    },
    fixed: {
      type: Boolean,
      default: false,
    },
    selectKey: {
      type: String,
      default: null,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      internalValue: [],
    }
  },
  computed: {
    tasks () {
      return this.internalValue.map(item => {
        const classes = [`bar-${item.color}`]

        if (this.showConflict && this.hasConflict(item)) {
          classes.push('bar-conflict')
        }

        if (this.selectKey && item[this.selectKey]) {
          classes.push('bar-selected')
        }

        if (this.fixed && !this.selectable) {
          classes.push('bar-static')
        }

        return {
          ..._omit(item, ['color']),
          progress: 0,
          custom_class: classes.join(' '), // eslint-disable-line camelcase
        }
      })
    },
  },
  watch: {
    value: {
      immediate: true,
      handler (value) {
        this.internalValue = value.slice()
      },
    },
    tasks () {
      if (this.$gantt) {
        this.$gantt.refresh(this.tasks)
      }
    },
  },
  mounted () {
    this.$gantt = new Gantt(this.$refs.gantt, this.tasks, {
      /* eslint-disable camelcase */
      view_mode: 'Day',
      header_height: 0,
      bar_height: 30,
      bar_corner_radius: 10,
      arrow_curve: 5,
      padding: 15,
      popup_trigger: '',
      disable_resize: true,
      disable_date_change: this.fixed,
      on_date_change: (task, start, end) => this.onDateChange(task.id, start, end),
      on_click: task => this.onClick(task.id),
      /* eslint-enable */
    })
  },
  methods: {
    hasConflict (task) {
      return this.internalValue
        .filter(item => item.id !== task.id && item.color === task.color)
        .some(item => areIntervalsOverlapping(
          { start: parseISO(task.start), end: parseISO(task.end) },
          { start: parseISO(item.start), end: parseISO(item.end) },
          { inclusive: true },
        ))
    },
    validate () {
      return !this.tasks.some(task => task.custom_class.includes('bar-conflict'))
    },
    onDateChange (taskId, start, end) {
      this.internalValue = this.internalValue
        .map(item => (item.id === taskId ? { ...item, start, end } : item))
      this.emitChanges()
    },
    onClick (taskId) {
      if (this.selectable && this.selectKey) {
        this.internalValue = this.internalValue.map(item => (item.id === taskId
          ? { ...item, [this.selectKey]: !item[this.selectKey] }
          : item))
        this.emitChanges()
      }
    },
    // Required for nested changes on dependencies
    emitChanges: _debounce(function emitChanges () {
      this.$emit('input', this.internalValue)
    }, 10),
  },
}
</script>

<style lang="scss">
.gantt .bar-label {
  font-size: 16px !important;
  font-weight: bold !important;
}

.gantt .bar-wrapper:hover .bar {
  opacity: 0.8 !important;
}

.gantt .bar-static:hover {
  cursor: default;
}
.gantt .bar-static:hover .bar {
  opacity: 1 !important;
}

.gantt .bar-red .bar {
  fill: #f9a240 !important;
}
.gantt .bar-blue .bar {
  fill: rgb(71, 114, 255) !important;
}
.gantt .bar-green .bar {
  fill: rgb(55, 216, 82) !important;
}
.gantt .bar-brown .bar {
  fill: rgb(135, 74, 27) !important;
}
.gantt .bar-buffer .bar {
  fill: url(#gradBuffer) !important;
}
.bar-buffer .bar-label {
  fill: rgb(50, 50, 50) !important;
}

.gantt .bar-conflict .bar {
  stroke: red !important;
  stroke-width: 5 !important;
}
.gantt .bar-selected .bar {
  stroke: #E034A6 !important;
  stroke-width: 5 !important;
}
</style>
